export class Fullscreen {
  constructor(eleRef) {
    this.ele = eleRef;
    this.isFullscreen = document.fullscreenElement !== null;
  }

  toggle() {
    if (this.isFullscreen) {
      return this.close();
    } else {
      return this.open();
    }
  }

  open() {
    if (this.ele.requestFullscreen) {
      return this.ele.requestFullscreen();
    } else if (this.ele.mozRequestFullScreen) { /* Firefox */
      return this.ele.mozRequestFullScreen();
    } else if (this.ele.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      return this.ele.webkitRequestFullscreen();
    } else if (this.ele.msRequestFullscreen) { /* IE/Edge */
      return this.ele.msRequestFullscreen();
    }
  }

  close() {
    if (document.exitFullscreen) {
      return document.exitFullscreen();
    } else if (document.mozCancelFullScreen) { /* Firefox */
      return document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
      return document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE/Edge */
      return document.msExitFullscreen();
    }
  }
}