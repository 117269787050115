import webApi from "./api"
import { logError } from "@/helper/utils"

export class PhotoUploadService {
  async uploadImage(formData, id, onProgressCallback = null) {
    try {
      const { data } = await webApi.post('/car-detail/image', formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        ...(onProgressCallback ? { onUploadProgress: onProgressCallback } : {}),
      });
      return ({
        ...data,
        id,
        onUpload: false
      })
    } catch (e) {
      logError(`Upload image error: ${e}`);
      return ({
        id,
        onUpload: false,
        err: true,
        url: ''
      })
    }
  }
  async deteleImg(filename) {
    return await webApi.delete(`/car-detail/image/${filename}`);
  }
  async rotate(img) {
    const { data } = await webApi.patch(`car-detail/image/rotate/${img.filename}`)
    return data;
  }
  async detectPredominantColors(formData) {
    try {
      const { data } = await webApi.post('/car-detail/image-detecting/predominant', formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
      });
      return data;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
}

const photoUploadService = new PhotoUploadService()

export default photoUploadService
